.MuiModal-root {
  z-index: auto !important;
}

.modal {
  opacity: 0;
  transition: opacity 1.6ms ease-in-out;
}

.modal.fade-in {
  opacity: 1;
}

.modal.fade-out {
  opacity: 0;
}